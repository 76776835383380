<template>
  <p-organizations v-if="$hasPermission('organizations.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Organizations from './../components/organizations/Organizations.vue';

export default {
  components: {
    'p-organizations': Organizations,
    'p-not-found': NotFound
  }
};
</script>
